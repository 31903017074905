



















































































































































































































































































































































.amountData {
  margin-bottom: 10px;

  span {
    margin-right: 20px;
  }
}
